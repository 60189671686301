<template>
  <section id="daftar-verifikasi-teknis">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_data_instansi"
                  v-model="master_data_instansi"
                  required
                  name="master_data_instansi"
                  :options="referenceData.ref_instansi"
                  placeholder="Daftar Instansi"
                  label="name"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              mode: 'pages',
              perPage:serverParams.length,
              setCurrentPage:selectPage,
              perPageDropdown: [15, 30, 60, 100],
              dropdownAllowAll: false,
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'children'">
                {{ props.row.children[0].nama }}
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                {{ props.row.last_update | moment }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_verified_ortala'">
                <b-badge
                  :variant="verifikasiVariant(props.row.is_verified_ortala).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ verifikasiVariant(props.row.is_verified_ortala).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_verified_bappeda'">
                <b-badge
                  :variant="verifikasiVariant(props.row.is_verified_bappeda).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ verifikasiVariant(props.row.is_verified_bappeda).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_verified_bppkad'">
                <b-badge
                  :variant="verifikasiVariant(props.row.is_verified_bppkad).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ verifikasiVariant(props.row.is_verified_bppkad).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'is_verified_diskominfo'">
                <b-badge
                  :variant="verifikasiVariant(props.row.is_verified_diskominfo).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ verifikasiVariant(props.row.is_verified_diskominfo).title }}
                </b-badge>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-b-tooltip.hover
                  :to="{name: 'kegiatan-verifikasi', params: { id: props.row.data_utama_id } }"
                  :data-id="props.row.id"
                  variant="info"
                  size="sm"
                  class="m-25"
                  title="Verifikasi Kegiatan"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>

      </b-col>
      <!-- post -->

    </b-row>
  </section>

</template>

<script>
import store from '@/store'
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BBadge, BRow, BButton, BCol, BCard, BFormGroup, BFormInput,
  VBTooltip, VBModal, BInputGroup, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/verifikasiColumns'

import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    // BPagination,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  setup() {
    const { statusColors, verificationColors } = stColors()
    const { newColumns, subColumns } = tableColumns()

    const yearBudget = localStorage.getItem('tahunAnggaran')
    const columns = newColumns
    const serverParams = {
      tahun_anggaran: yearBudget,
      sort_by: [
        {
          column: 'modified_on',
          asc_desc: 'asc',
        },
      ],
      start: 0,
      length: 30,
    }

    return {
      yearBudget,
      statusColors,
      verificationColors,
      columns,
      subColumns,
      serverParams,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      return `${diff} Hari`
    },
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === 10 || (role > 69 && role < 80)) {
      next()
    } else {
      next('/')
    }
  },
  data() {
    return {
      isLoading: false,
      userData: getUserData(),
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      modalTitle: '',
      totalRecords: 0,
      selectPage: 1,
      rows: [],
      app_rows: [],
      searchTerm: '',
      subSearchTerm: '',
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      errorStat: false,
      errorMsg: '',
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    if (localStorage.getItem('master_instansi_id')) {
      this.master_data_instansi = JSON.parse(localStorage.getItem('master_instansi_id'))
    }
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    const { page, currentPage, currentPerPage } = store.state.app
    if (page === 'verifikasi' && currentPage && currentPerPage) {
      this.selectPage = currentPage
      this.serverParams.length = currentPerPage
      this.updateParams({ start: (currentPage - 1) * this.serverParams.length })
    }
    this.doFilter()
    store.commit('app/SET_PAGE', 'verifikasi')
    this.getReference()
  },
  methods: {
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    getReference() {
      this.$http.get('/recommendation/list-references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data.references
          }
        })
    },
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    verifikasiVariant(status) {
      if (this.verificationColors[status]) {
        return this.verificationColors[status]
      }
      return {
        title: 'Menunggu Verifikasi Teknis',
        class: 'light-secondary',
      }
    },
    globalFilter() {
      const params = []
      params.push({
        column: 'tahun_anggaran',
        value: this.yearBudget,
      })
      if (this.master_data_instansi) {
        params.push({
          column: 'instansi_id',
          value: String(this.master_data_instansi.instansi_id),
        })
        localStorage.setItem('master_instansi_id', JSON.stringify(this.master_data_instansi))
      } else {
        localStorage.removeItem('master_instansi_id')
      }
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      store.commit('app/CURRENT_PAGE', params.currentPage)
      this.selectPage = store.state.app.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.doFilter()
    },
    onPerPageChange(params) {
      store.commit('app/CURRENT_PER_PAGE', params.currentPerPage)
      this.updateParams({ length: params.currentPerPage, start: (this.selectPage - 1) * params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({ start: 0 })
      params.map(param => {
        let columName = param.field
        if (param.field === 'instansi') {
          columName = 'instansi'
        }
        if (param.field === 'jenis_pengadaan') {
          columName = 'jenis_pengadaan_id'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams({ start: 0 })
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry

        let columName = key
        if (key === 'instansi') {
          columName = 'instansi'
        }
        filterz.push({
          column: columName,
          value: valuez,
        })
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/verification/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
